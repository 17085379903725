import React, { Component } from 'react';
import './cta.css';

export class Cta extends Component {
  render() {
    return (
      <div className="cta" id="lidWorden">
        <div className="cta-inner">
          <div className="cta-content">
            <p className="cta-intro">
                Word lid van de plezantste supportersclub van 'De Plezantste Compagnie'!
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZirFm5qOobWsEd3xtzhtlcBRLaFYQEzDer6IkOOGRn7pyRA/viewform" target="_blank" rel="noopener noreferrer" className="about-btn">Word lid</a>
          </div>
        </div>
      </div>
    )
  }
}

export default Cta
