import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Hero from '../components/Hero';
import About from '../components/About';
import Peter from '../components/Peter';
import Cta from '../components/Cta';

const IndexPage = () => (
  <Layout>
    <SEO title="Leuven Lads"/>
    <Hero />
    <About />
    <Peter />
    <Cta />
  </Layout>
)

export default IndexPage
