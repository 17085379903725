import React, { Component } from 'react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import slide1 from '../images/swiper/leuven-lads1.jpg';
import slide2 from '../images/swiper/leuven-lads2.jpg';
import slide3 from '../images/swiper/leuven-lads3.jpg';
import './hero.css';
import './swiper.css';

SwiperCore.use([Autoplay, EffectFade]);

export default class Hero extends Component {
  render() {
    return (
      <div className='hero'>

        <Swiper
          spaceBetween={0}
          slidesPerView={1}
          effect="fade"
          autoplay={{delay:5000}}
          onSlideChange={() => console.log('slide change')}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <img src={slide1} alt="Foto van de Leuven Lads"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide2} alt="Foto van de Leuven Lads"></img>
          </SwiperSlide>
          <SwiperSlide>
            <img src={slide3} alt="Foto van de Leuven Lads"></img>
          </SwiperSlide>
        </Swiper>
      </div>
    )
  }
}
