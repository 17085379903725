import React, { Component } from 'react';
import './peters.css';
import casper from '../images/peters/casper.png';
import amber from '../images/peters/amber.png';

export class About extends Component {
  render() {
    return (
      <div className="peters" id="peters">
        <div className="peters-inner">
            <div className="peters-intro">
                <h2 className="peters-title">Onze peter en meter</h2>
                <p>Leuven Lads heeft ook dit jaar weer een fantastische peter en meter weten te strikken:</p>
            </div>
            <div className="peters-content">
                <div className="peter">
                    <div className="peter-img">
                        <img src={casper} alt='Casper De Norre'/>
                    </div>

                    <div className="peter-text">
                        <div className="peter-name">Casper De Norre</div>
                        <div className="peter-nickname">"Peter Casper"</div>
                        <div className="peter-desc">Schitterende kadee, zowel op als naast het veld. Sinds zijn komst naar Leuven in 2020 zijn wij alleen maar meer beginnen houden van deze kilometervreter met een verschroeiende rechtse poot.</div>
                    </div>
                </div>
                <div className="peter">
                    <div className="peter-img">
                        <img src={amber} alt='Amber Tysiak'/>
                    </div>
                    <div className="peter-text">
                        <div className="peter-name">Amber Tysiak</div>
                        <div className="peter-nickname">"Metie Amber"</div>
                        <div className="peter-desc">Ook dit jaar hebben we zowel een meter als een peter.
We zijn enorm blij dat deze Red Flame nog een seizoen voor OHL blijft vlammen en ongelooflijk fier om haar ‘onze metie’ te mogen noemen</div>
                    </div>
                </div>
            </div>
        </div>
      </div>
    )
  }
}

export default About
