import React, { Component } from 'react';
import './about.css';

export class About extends Component {
  render() {
    return (
      <div className="about" id="over">
        <div className="about-inner">
          <div className="about-content">
            <h2 className="about-title">Leuven Lads</h2>
            <p className="about-intro">
                De plezantste supportersclub van 'De Plezantste Compagnie'
            </p>
            <p className="about-text">
                Welkom bij de Leuven Lads, sinds 2011 trouw op post om Oud-Heverlee Leuven naar de overwinning te helpen. Of je nu veel of weinig van voetbal weet, of je nu vaak of af en toe komt, bij ons altijd pret verzekerd.
            </p>
            <p className="about-text">
                Niet altijd de grootste bus naar de uitverplaatsingen, maar fun altijd verzekerd. Aarzel niet om eens mee te gaan!
            </p>
            <p className="about-text">
                Elk jaar gaan we voor een weekendje verbroederen met de supporters van Leicester, onze neefjes van over het kanaal. Hou onze sociale media in het oog voor meer info hierover!
            </p>
            <a href="https://docs.google.com/forms/d/e/1FAIpQLSfZirFm5qOobWsEd3xtzhtlcBRLaFYQEzDer6IkOOGRn7pyRA/viewform" target="_blank" rel="noopener noreferrer" className="about-btn">Word lid</a>
          </div>
        </div>
      </div>
    )
  }
}

export default About
